$primary: #1b5194 !default;
$theme-contrast-color: #fff !default;
$theme1: #f08500 !default;
$theme2: #9FA9AD !default;

$border-width: 1px;
$theme-colors: (
        'theme1': $theme1
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$enable-responsive-font-sizes: true;
$btn-font-weight: bold;
$headings-font-weight: 700;
$enable-rounded: false;
$enable-shadows: false;
$link-decoration: underline;
$link-color: $theme1;
$nav-link-padding-x: .5rem;
$btn-padding-x: 1rem;
$btn-font-weight: normal;
$border-radius: 0px;
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-border-radius-lg: $border-radius;
$headings-color: $primary;
$navbar-nav-link-padding-x: 1rem;

$scale-factor: .98;

//$mijn-sidebar--bg: none;
//$mijn-sidebar--menu-block-bg: $primary;
//$mijn-sidebar--spacing-pt-lg: 0;
//$mijn-sidebar--welcome-color: $primary !default;

// import font
@import url("https://use.typekit.net/xup6heb.css");
$font-family-base: "oscine", sans-serif;

@import '@/src/scss/main.scss';

// SCSS Components
//@import '@/src/scss/components/mijn-sidebar.scss'; // Mijn omgeving css (sidebar theme)
@import '@/src/scss/components/hero-video.scss'; // Hero video

strong {
  color: $primary;
}

.topbar-card--link {
  @extend .nav-link;
}

nav.navbar .nav-link {
  color: $primary !important;
  transition: background-color .3s, color .3s;
  font-weight: bold;
  &:hover {
    background-color: #f5f5f5;
    color: darken($primary, 15%) !important;
  }
}

.navbar-brand--img {
  height: 3.5rem !important;
  @include media-breakpoint-up(lg) {
    height: 3rem !important;
  }
}

.video-hero-wrapper {
  margin-bottom: -80px;
  @extend .z-r1;
  .container {
    @extend .position-absolute;
    @extend .text-center;
    height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .video-hero--content {
      display:none;
    }
    .video-hero--title {
      display: none;
    }
    .video-hero--subtitle {
      display: none;
    }
    @include media-breakpoint-up(md) {
      height: 500px;
    }
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: -53px;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: -150px;
    height: 500px;
  }
}

.hero-line--top {
  position: absolute;
  right: 0;
  width: 110%;
  height: auto;
  bottom: 43px;
  z-index: -1;
  @include media-breakpoint-up(sm) {
    bottom: 20px;
  }
  @include media-breakpoint-up(md) {
    bottom: 105px;
  }
  @include media-breakpoint-up(xl) {
    bottom: 87px;
  }
  @include media-breakpoint-up(xxxl) {
    bottom: 65px;
  }
}

.hero-line--bottom {
  position: absolute;
  right: 0;
  width: 110%;
  height: auto;
  bottom: -54px;
  z-index: -1;
  filter: contrast(0.95);
  @include media-breakpoint-up(sm) {
    bottom: -105px;
  }
  @include media-breakpoint-up(md) {
    bottom: -46px;
  }
  @include media-breakpoint-up(lg) {
    bottom: -95px;
  }
  @include media-breakpoint-up(xl) {
    bottom: -144px;
  }
  @include media-breakpoint-up(xxxl) {
    bottom: -165px;
  }
}

.quick-menu-item {
  color: white;
  transition: transform .3s;
  &:hover {
    transform: scale($scale-factor);
  }
  img {
    @extend .p-2;
  }
  @include media-breakpoint-down(md) {
    img {
      padding: 1.5rem !important;
    }
  }
  &:nth-child(1) {
    .quick-menu--bg {
      background-color: $primary;
    }
  }
  &:nth-child(2) {
    .quick-menu--bg {
      background-color: $theme1;
    }
  }
  &:nth-child(3) {
    .quick-menu--bg {
      background-color: $theme2;
    }
  }
}

.agenda-split--left {
  @extend .col-lg-7;
  .agenda-split--line-break {
    border-color: $white;
    @extend .my-3;
  }
}

.agenda-split--left .card {
  background: none;
  color: $white;
  .date-badge {
    @extend .mt-2, .mb-1;
  }
  .card-title {
    color: $white;
    @extend .mb-0;
  }
  .read-on {
    color: $white;
  }
}

.agenda-split--right {
  @extend .col-lg-5;
  .side-item {
    transition: color .2s;
    color: $white;
    .date-label {
      transition: color .2s;
      color: $white;
    }
    &:hover {
      color: $primary;
      .date-label {
        color: $primary;
      }
    }
  }
}

.card-stretch-hover {
  .card {
    width: 100%;
    transition: transform .3s;
  }
  .fa-chevron-right {
    transition: margin-left .2s;
  }
  &:hover {
    .card {
      transform: scale($scale-factor);
    }
    .fa-chevron-right {
      margin-left: .5rem !important;
    }
  }
}

.watermark--bar {
  background-color: #D5D7D9;
  margin-top: 125px;
  @include media-breakpoint-up(xxl) {
    margin-top: 1rem;
  }
}

.footer--bottom {
  background-image: url('../img/footer-pattern.png');
}

.footer-cutout {
  width: 100%;
  height: auto;
}

.footer-logo {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 150px;
  @include media-breakpoint-up(xxl) {
    width: 160px;
  }
  @include media-breakpoint-up(xxxl) {
    width: 200px;
  }
}

footer {
  .social--icons {
    color: white;
    font-size: 1.3rem;
  }
  a:not(.newsletter-btn) {
    transition: color .2s;
    &:hover {
      color: $theme1 !important;
    }
  }
}

.cutout-coverup {
  width: 100%;
  height: 4px;
  background: #f8f9fa;
  position: absolute;
  top: -2px;
}

.special-bordered-content {
  padding: 6px;
  @include media-breakpoint-up(md) {
    padding: 10px;
  }
}

.page-head-banner {
  min-height: 200px;
  max-height: 450px;
}

.banner-title {
  left: 1rem;
  bottom: 1rem;
}

.contact-map {
  display: none;
}

.my-menu-head {
  background-color: $theme1;
  border: 0;
  h5 {
    background-color: $white;
    @extend .px-3, .py-2, .d-inline-block;
  }
}

.accordion button::after {
  color: $theme1;
}

.ticket-list-item--inner {
  border: 0;
}

.thead-light {
  th {
    color: $primary !important;
  }
}

.footer-line-height {
  line-height: 2rem;
}

.mobile-hamburger-color {
  color: $primary;
}
